<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Contas a pagar</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Contas a
                  <span>Pagar</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <section class="container">
          <!-- Conteúdo principal -->
          <div
            v-if="exibeConteudoPrincipal"
            class="card-body"
          >
            <div class="row">
              <div class="col-12">
                <button
                  v-if="!exibeFiltro"
                  class="btn btn-sm btn-primary"
                  @click="exibeFiltro = true"
                >
                  Exibir filtro
                </button>
                <button
                  v-else
                  class="btn btn-sm btn-primary"
                  @click="exibeFiltro = false"
                >
                  Ocultar filtro
                </button>
              </div>
            </div>
            <div
              v-if="exibeFiltro"
              class="row"
            >
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Plataforma:</label>
                <input
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Aluno:</label>
                <input
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Responsavel:</label>
                <input
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Tipo Pagamento:</label>
                <select class="form-control">
                  <option value="">
                    Selecione o tipo
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Origem:</label>
                <select class="form-control">
                  <option value="">
                    -Selecione a Origem-
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-4">
                <label>Status:</label>
                <select class="form-control">
                  <option value="">
                    Selecione o Status
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 text-center mt-4">
                <label>Emissão:</label>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>De:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>Até:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 text-center mt-4">
                <label>Vencimento:</label>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>De:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>Até:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 text-center mt-4">
                <label>Data Pagamento:</label>
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>De:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <small>Até:</small>
                    <input
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4">
                <br>
                <button class="btn btn-sm btn-primary">
                  <small>Limpa Filtros</small>>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          Total Cobrado:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          Total Pago:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          A vencer:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 p-4">
                <div class="card border-left-primary shadow h-100">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div
                          class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                        >
                          Vencido:
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-10" />
              <div class="col-2">
                <button
                  class="btn btn-sm btn-primary"
                  @click="exibeModalCadastroContaPaga()"
                >
                  + Adicionar Conta
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 table-responsive mt-2">
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr class="text-center">
                      <th>
                        <small class="font-weight-bold">Plataforma</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Aluno</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Valor</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Emissão</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Vencimento</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Pagamento</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Status</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Link</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Link NF</small>
                      </th>
                      <th>
                        <small class="font-weight-bold">Logística</small>
                      </th>
                    </tr>
                  </thead>
                  <!-- <tbody v-if="fastOcorrenciaLoading">
                    <tr>
                      <td colspan="5" class="text-center">
                        Carregando Ocorrências...
                      </td>
                    </tr>
                  </tbody> -->
                  <tbody>
                    <tr />
                  </tbody>
                  <!-- <tbody v-else>
                    <tr>
                      <td colspan="5" class="text-center">
                        Nenhuma Ocorrência Cadastrado
                      </td>
                    </tr>
                  </tbody> -->
                </table>
              </div>
              <!-- <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="10"
                  :items="fastOcorrenciaFiltro"
                  @changePage="fastOcorrenciaPagination = $event"
                />
              </div> -->
            </div>
          </div>
          <div
            v-else-if="exibeUploadDocumento"
            class="card-body"
          >
            <p class="text-center">
              Faça o upload do arquivo desejado
            </p>

            <p class="text-center">
              arraste e solte o arquivo tal
            </p>
          </div>
          
          <div
            v-else
            class="card-body"
          >
            <b-card no-body>
              <b-tabs card>
                <b-tab
                  title="Registro Individual"
                  active
                >
                  <b-card-text>
                    <div class="row">
                      <div class="col-3">
                        <p>Tipo de Registro: Saída</p>
                      </div>
                      <div class="col-7" />
                      <div class="col-2">
                        <button class="btn btn-sm btn-primary">
                          Importar XML
                        </button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Nome do Registro:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Valores do Registro:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Prazo Personalizado:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Periodo de Competência:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Conta Corrente:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col align-self-end mt-4">
                        <label>Observação:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Método de pagamento:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Data de Vencimento:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Repetir:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="col">
                        <div class="col-sm-12 col-md-12 col-lg-12 mt-4 text-center">
                          <label>Arraste ou clique para escolheros arquivos:</label>
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="Registro em Massa">
                  <b-card-text>
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Conta Corrente:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                        <label>Modelo de Arquivo:</label>
                        <input
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <br>
                      <br>
                      <div class="col-12 text-center mt-4">
                        <p>arraste e solte aqui o arquivo do formato que deseja...</p>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>  
          <!-- /Conteúdo principal -->
        </section>
        <!-- Inicio Botão voltar Pag inícico -->
        <div
          v-if="exibeConteudoPrincipal"
          class="container"
        >
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' +
                  $route.params.id_plataforma +
                  '/tesouraria'
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
        <!-- Fim Botão voltar Pag inícico -->

        <!-- Inicio Botão voltar dentro da pag -->
        <div
          v-else
          class="container"
        >
          <div class="mt-5 mb-5">
            <button
              class="btn btn-red-hollow"
              @click="exibeConteudoPrincipal = true, exibeUploadDocumento = false, exibeCriarRegistro = false"
            >
              Voltar
            </button>
          </div>
        </div>
        <!-- Fim Botão voltar dentro da pag -->
      </div>
    </div>
    <!-- modal -->
    <modal
      name="exibeModalCadastroContaPaga"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Escolha uma Opção</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('exibeModalCadastroContaPaga')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="card"
                          @click.prevent="hideModal('exibeModalCadastroContaPaga'); exibeConteudoPrincipal = false; exibeUploadDocumento = true"
                        >
                          <div class="card-body text-center">
                            <h5 class="card-title">
                              Enviar Documento
                            </h5>
                            <img
                              :src="require('@/assets/images/app/upload-file.png')"
                              style="max-height: 100px"
                            >
                            <p class="card-text">
                              Clique aqui para Enviar um Documento.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          class="card"
                          @click.prevent="hideModal('exibeModalCadastroContaPaga'); exibeConteudoPrincipal = false"
                        >
                          <div class="card-body text-center">
                            <h5 class="card-title ">
                              Criar Registro Financeiro
                            </h5>
                            <img
                              :src="require('@/assets/images/app/edit.png')"
                              style="max-height: 100px"
                            >
                            <p class="card-text">
                              Clique aqui para Criar um Registro Financeiro.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoTesourariaContasPagar",
  components: {},
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      exibeFiltro: false,
      exibeConteudoPrincipal: true,
      exibeUploadDocumento: false,
      exibeCriarRegistro: false,
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (
            this.$store.state.fastPlataformaModulos.modulo_secretaria
          ) {
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    exibeModalCadastroContaPaga() {
      this.showModal("exibeModalCadastroContaPaga");
    },
    
  },
};

</script>

<style></style>
